body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: #383E42;
  background-image: url('assets/background.png');
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
