.app-container{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;    
}

.app-container-desktop{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  grid-template-columns: 1fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.title-container{
  display: flex;
  align-items: center;
  justify-content:space-evenly;
  width: 100%;
}

.lottie-container{
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: left;
  width: 100%;
  left: -20px;
  bottom: 0;
}

h1{
  font-size: 3em;
  margin: 5px;
	background-clip: text;
  -webkit-background-clip: text;
  background-size: contain;
  background-position-y: top;
}

.link-container{
  display: flex;
  background: rgb(255 255 255 / 70%);
  padding: 50px;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container{
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: black;
  font-style: oblique;
  z-index: 9;
  cursor: pointer;
}

.fotter-content{
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 100%;
  rotate: -90deg;
  translate: 25px -30px;
  font-size: 8px;
}

.informations-container{
  margin: 15px 0;
  font-weight: bold;
}

.img-background{
  width: 95%;
}

.shake-container{
  bottom: 5px;
  display: flex;
  justify-content: center;
}


.pointer{
  cursor: pointer;
  width: 40px;
}

.invisible{
  display: none;
}

.score-increment{
  font-size: 3em;
  margin-bottom: 5px;
  font-family: unset;
}